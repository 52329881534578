import { UserGroup } from './user-group';
import { UserProfile } from './user-profile';


export class User {
   public id!: string;
   public displayName!: string;
   public firstname?: string;
   public lastname?: string;
   public userPrincipalName!: string;

   /**
    * Gets the user profile.
    */
   public profile!: UserProfile;

   /**
    * Gets the user groups this user is a member of.
    */
   public memberOf!: UserGroup[];


   public static parse(data: any): User {
      const user = new User();
      if (data) {
         user.id = data.id;
         user.displayName = data.displayName;
         user.firstname = data.firstname;
         user.lastname = data.lastname;
         user.userPrincipalName = data.userPrincipalName;
         user.profile = UserProfile.parse(data.profile);
         user.memberOf = (data.memberOf || []).map((group: any) => UserGroup.parse(group));
      }
      return user;
   }

   public setUserProfile(data: any): void {
      if (data) {
         if (!this.profile) { this.profile = new UserProfile(); }
         this.profile.mail = data.mail;
         this.profile.alternativeMail = data.alternativeMail;
      }
   }
}

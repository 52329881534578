export class ModuleEntryPointDefinition {
   /**
    * The icon representin the entry point.
    */
   icons?: { primary: string; secondary?: string };
   /**
    * The display name representing the entry point.
    */
   displayName?: string;
   /**
    * The navigation function to the module's the entry point.
    */
   navigate!: () => Promise<boolean>;
   /**
    * A function returning whether the entry point is enabled.
    */
   isEnabled?: () => boolean;

   newSection?: boolean = false;

   public constructor(init?: ModuleEntryPointDefinition) {
      Object.assign(this, init);
   }
}

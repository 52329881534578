import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class BusyService {

   private isBusyValue = false;
   private messageValue?: string;
   private onIsBusyChangedValue = new Subject<boolean>();

   constructor() { }

   // -------------------------------------------------------------- Properties

   public get isBusy(): boolean {
      return this.isBusyValue;
   }

   public get onIsBusyChanged(): Observable<boolean> {
      return this.onIsBusyChangedValue;
   }

   public get message(): string | undefined {
      return this.messageValue;
   }

   // ---------------------------------------------------------- Login / logout

   public setBusy(message?: string): void {
      setTimeout(() => {
         this.messageValue = message;
         this.isBusyValue = true;
      }, 1);
   }

   public clearBusy(): void {
      setTimeout(() => {
         this.isBusyValue = false;
         this.messageValue = undefined;
      }, 1);
   }
}

import { DurableFunctionCustomStatus } from './durable-function-custom-status';
import { DurableFunctionCustomStatusError } from './durable-function-custom-status-error';
import { HistoryEventEntry } from './history-event-entry';

export class DurableFunctionStatus<TOutput, TCustomStatus extends DurableFunctionCustomStatus<DurableFunctionCustomStatusError>> {
   public static readonly RUNTIME_STATUS_COMPLETED = 'Completed';
   public static readonly RUNTIME_STATUS_FAILED = 'Failed';
   public static readonly RUNTIME_STATUS_PENDING = 'Pending';
   public static readonly RUNTIME_STATUS_RUNNING = 'Running';

   public createdTime?: string;
   public input?: string;
   public instanceId?: string;
   public name?: string;
   public lastUpdatedTime?: string;
   public output?: string | TOutput;
   public runtimeStatus?: string;
   public historyEvents?: HistoryEventEntry[];
   public customStatus?: TCustomStatus;

   public get isRunning(): boolean {
      return this.runtimeStatus === DurableFunctionStatus.RUNTIME_STATUS_PENDING || this.runtimeStatus === DurableFunctionStatus.RUNTIME_STATUS_RUNNING;
   }
}

import { ApplicationRef, Component, EnvironmentInjector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill';
import { ContentChange, QuillModules } from 'ngx-quill';
import { AscHtmlEditorCustomModule } from './html-editor-custom.module';

@Component({
   selector: 'asc-html-editor',
   templateUrl: './html-editor.component.html',
   styleUrls: ['./html-editor.component.scss'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: AscHtmlEditorComponent
      }
   ]
})
export class AscHtmlEditorComponent implements OnInit, ControlValueAccessor {

   public static DEFAULT_MODULES: QuillModules = {
      toolbar: [
         ['bold', 'italic', 'underline', 'strike'],      // toggled buttons
         [{ 'color': [] }, { 'background': [] }],        // dropdown with defaults from theme
         ['clean'],                                      // remove-formatting button
         ['link'],
         [{ 'list': 'ordered' }, { 'list': 'bullet' }]
      ],
      history: {
         delay: 1000,
         maxStack: 100,
         userOnly: true
      }
   };

   @Input()
   public customModules: AscHtmlEditorCustomModule[] = [];
   private touched = false;
   private disabled = false;
   private htmlValue: string | null = null;
   private onChange = (html: string | null) => { };
   private onTouched = () => { };
   private editor?: Quill;

   public modules: QuillModules = AscHtmlEditorComponent.DEFAULT_MODULES;


   constructor(
      private appRef: ApplicationRef,
      private environmentInjector: EnvironmentInjector
   ) { }


   ngOnInit() {
      (this.customModules || []).forEach(module => {
         if (module.name && module.definitionType) {
            Quill.register(`modules/${module.name}`, module.definitionType);
         }
      });
   }


   public insertHtml(htmlTemplate: string) {
      if (!this.editor && !this.disabled) { return; }

      if (this.editor) {
         const pos = this.editor.getSelection()?.index || 0;
         const delta = this.editor.clipboard.convert(htmlTemplate);
         this.editor.clipboard.dangerouslyPasteHTML(pos, htmlTemplate, 'user');
         this.editor.setSelection(pos + delta.length(), 0, 'user');
         setTimeout(() => {
            this.focus();
         }, 100);
      }
   }

   // ------------------------------------------------------------ Quill events

   onEditorCreated(quill: Quill) {
      this.editor = quill;

      // Attach toolbar
      (this.customModules || []).forEach(module => {
         module.attachToToolbar(quill, module.config, this.appRef, this.environmentInjector);
         module.onLoaded();
      });

      // Set initial content
      const delta = quill.clipboard.convert(this.htmlValue || undefined);
      quill.setContents(delta);
   }

   onContentChanged(change: ContentChange) {
      this.htmlValue = change.html;

      if (change.source === 'user') {
         this.onChange(this.htmlValue);
         this.markAsTouched();
      }
   }

   // ------------------------------------- ControlValueAccessor implementation

   registerOnChange(onChange: any) {
      this.onChange = onChange;
   }

   registerOnTouched(onTouched: any) {
      this.onTouched = onTouched;
   }

   markAsTouched() {
      if (!this.touched) {
         this.onTouched();
         this.touched = true;
      }
   }

   setDisabledState(disabled: boolean) {
      this.disabled = disabled;

      if (this.editor) {
         if (disabled) {
            this.editor.disable();
         }
         else {
            this.editor.enable();
         }
      }
   }

   writeValue(html: string | null): void {
      this.htmlValue = html;

      if (this.editor) {
         const delta = this.editor.clipboard.convert(this.htmlValue || undefined);
         this.editor.setContents(delta);
      }
   }

   // ------------------------------------- ControlValueAccessor implementation

   public focus() {
      this.editor?.focus();
      this.markAsTouched();
   }
}

import { DurableFunctionCustomStatusError } from "./durable-function-custom-status-error";

export class DurableFunctionCustomStatus<TError extends DurableFunctionCustomStatusError> {
   /**
    * Gets the name of the activity the workflow is currently on.
    */
   public activityName?: string;
   /**
    * Gets the error the occurred in the workflow.
    */
   public error?: TError;
}

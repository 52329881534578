import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { AuthenticationService } from '../authentication/services/authentication.service';
import { ModuleManagerService } from './module-manager.service';
import { User } from '../model/user';
import { AscGenericDialogComponent } from '../components/generic-dialog/generic-dialog.component';
import { NotificationBarService } from './notification-bar.service';
import { ClientConfiguration } from '../model/client-configuraton';
import { TelemetryService } from './telemetry.service';
import { UserProfile } from '../model/user-profile';
import { CoreService } from './core.service';


@Injectable()
export class SessionService {

   public static readonly DATA_SOURCE_FALLBACK = 'fallback';

   public dataSource?: string;
   private isAuthenticatedValue = false;
   private userValue!: User;
   private isAuthenticatedSubject = new Subject<boolean>();
   private isLoggingInSubject = new Subject<void>();
   private clientConfiguration!: ClientConfiguration;

   constructor(
      private coreService: CoreService,
      private telemetryService: TelemetryService,
      private authenticationService: AuthenticationService,
      private moduleManagerService: ModuleManagerService,
      private notificationBarService: NotificationBarService

   ) { }

   public initialize(clientConfiguration: ClientConfiguration): void {
      this.clientConfiguration = clientConfiguration;

      // Register to events
      this.authenticationService.isAuthenticatedChanged.subscribe(async (isAuthenticated: boolean) => {
         if (this.isAuthenticatedValue !== isAuthenticated) {
            this.isAuthenticatedValue = isAuthenticated;

            // Load user infos
            if (this.authenticationService.user) {
               this.userValue = User.parse(this.authenticationService.user);
            }

            if (this.isAuthenticatedValue) {
               const userProfileData = await this.coreService.loadUserProfileAsync();
               this.userValue.setUserProfile(userProfileData);

               this.telemetryService.setAuthenticatedUserContext(this.userValue.id, undefined, true);
               this.telemetryService.logEvent('Auth.Logon');
            }
            else {
               this.telemetryService.clearAuthenticatedUserContext();
               this.telemetryService.logEvent('Auth.Logoff');
            }

            this.isAuthenticatedSubject.next(isAuthenticated);
         }
      });

      this.authenticationService.isLoggingInChanged.subscribe(() => this.isLoggingInSubject.next());

      // Update internal state
      this.authenticationService.checkIsAuthenticated();
   }

   // ---------------------------------------------------------- Event handling

   public get isLoggingInChanged(): Observable<void> {
      return this.isLoggingInSubject;
   }

   // -------------------------------------------------------------- Properties

   public get user(): User {
      return this.userValue;
   }

   public get isAuthenticated(): boolean {
      return this.isAuthenticatedValue;
   }

   public get isAuthenticatedChanged(): Observable<boolean> {
      return this.isAuthenticatedSubject;
   }

   // ---------------------------------------------------------- Login / logout

   /**
    * Prompts the user to enter the credentials in order to login.
    */
   public async loginAsync(redirectUrl?: string): Promise<void> {
      await this.authenticationService.loginAsync(this.clientConfiguration.authentication.provider, redirectUrl);
   }

   /**
    * Logs the user out and closes the session.
    */
   public logoutAsync(): Promise<void> {
      return this.authenticationService.logoutAsync();
   }

   // ---------------------------------------------------------- Module loading

   public async initializeModulesAsync(router: Router, routes: Routes): Promise<string | undefined> {
      // Load modules based on user context
      console.group('[Session]', 'Loading modules');
      const userPrincipalName = this.authenticationService.user?.userPrincipalName || '';
      const moduleConfigurations = await this.moduleManagerService.loadModuleConfigurationAsync(userPrincipalName);
      await this.moduleManagerService.loadModulesAsync(moduleConfigurations);
      console.groupEnd();

      // Update routes for loaded modules
      const moduleIDs = moduleConfigurations.map(item => item.id);
      const config: Routes = this.moduleManagerService.getModuleRoutes(routes, moduleIDs);
      router.resetConfig(config);

      return this.authenticationService.getRedirectUrl();
   }

   // ----------------------------------------------------- System availability

   public checkDataSourceAvailability(dataSource: string | null) {

      if (!dataSource || this.dataSource === SessionService.DATA_SOURCE_FALLBACK) { return; }

      if (this.dataSource && this.dataSource !== dataSource && dataSource === SessionService.DATA_SOURCE_FALLBACK) {
         AscGenericDialogComponent.showOkDialogAsync(
            'Systemfehler',
            'Das System ist zurzeit nicht erreichbar.\nEs sind nur Notfall-Funktionen möglich.\n\nBitte starten Sie die App neu.'
         );
      }

      // Update
      if (this.dataSource !== dataSource) {
         this.dataSource = dataSource;
         console.log('[Session]', `Data source changed to '${dataSource}'.`);
      }

      // Notification bar
      if (dataSource === SessionService.DATA_SOURCE_FALLBACK) {
         this.notificationBarService.setMessage(`Die ASC-Datenbank ist nicht erreichbar. ${this.clientConfiguration.title} ist nur eingeschränkt verfügbar.`);
      }
   }
}

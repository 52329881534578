<div class="asc-view fx-flex">
   <div class="asc-dashboard-header">
      <img src="/assets/images/ari-logo.svg" alt="asc-logo" />
   </div>

   <div class="asc-dashboard-content">
      <div class="asc-dashboard-modules">
         <div class="asc-module-button-container" *ngFor="let featureNavigationItem of featureNavigationItems">
            <div *ngIf="featureNavigationItem.entryPoints.length > 1; then multiEntryPoints else singleEntryPoint">
            </div>
            <ng-template #multiEntryPoints>
               <button mat-raised-button class="asc-module-button" matTooltip="{{featureNavigationItem.description}}"
                  [matMenuTriggerFor]="menu">
                  <asc-module-icon [primary]="featureNavigationItem.icons.primary"
                     [secondary]="featureNavigationItem.icons.secondary" size="lg"></asc-module-icon>
                  <p>{{featureNavigationItem.displayName}}</p>
               </button>
               <mat-menu #menu="matMenu" [overlapTrigger]="true">
                  <button mat-menu-item (click)="navigateTo(entryPoint)"
                     [ngClass]="{'asc-new-section': entryPoint.newSection}"
                     [disabled]="(entryPoint.isEnabled) ? !entryPoint.isEnabled() : false"
                     *ngFor="let entryPoint of featureNavigationItem.entryPoints">
                     <asc-module-icon [primary]="entryPoint.icons?.primary" [secondary]="entryPoint.icons?.secondary">
                     </asc-module-icon>
                     {{entryPoint.displayName}}
                  </button>
               </mat-menu>
            </ng-template>
            <ng-template #singleEntryPoint>
               <button mat-raised-button class="asc-module-button" matTooltip="{{featureNavigationItem.description}}"
                  (click)="navigateTo(featureNavigationItem.entryPoints[0])"
                  [disabled]="(featureNavigationItem.entryPoints[0].isEnabled) ? !featureNavigationItem.entryPoints[0].isEnabled() : false">
                  <asc-module-icon [primary]="featureNavigationItem.icons.primary"
                     [secondary]="featureNavigationItem.icons.secondary" size="lg"></asc-module-icon>
                  <p>{{featureNavigationItem.displayName}}</p>
               </button>
            </ng-template>
         </div>
      </div>

      <div class="asc-dashboard-info">
         <h1>Herzlich Willkommen</h1>
         <p>
            zum Online-Service der AR Informatik AG.
         </p>
         <p>
            <img src="assets/images/ari-service-desk.png">
         </p>
         <p>
            Bei Fragen steht Ihnen der Service&nbsp;Desk gerne zur Verfügung.
         </p>
         <p>
            <b>Bediente Servicezeiten:</b>
            <br /> Montag - Freitag, 7:00 - 17:00 Uhr
            <br /> Tel:
            <a href="tel:+41713539444">071 353 94 44</a>
            <br />
            <a href="mailto:servicedesk@ari-ag.ch">servicedesk&#64;ari-ag.ch</a>
         </p>
         <p>
            Für Pikett-Kunden steht der Service&nbsp;Desk 7&nbsp;*&nbsp;24h zur Verfügung.
         </p>
      </div>
   </div>
</div>
import { Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';

import { User } from '../model/user';
import { IAuthenticationProvider } from './iauthentication-provider';
import { ClientConfiguration } from '../../model/client-configuraton';
import { TelemetryService } from '../../services/telemetry.service';
import { EAppInsightsSeverityLevel } from '../../model/application-insights';
import { AccessToken } from '../model/access-token';

/**
 * Microsoft Authentication Library (MSAL)
 */
@Injectable()
export class MSALProviderService implements IAuthenticationProvider {

   private readonly KEY_LAST_USED_UPN = 'asc.authentication.last-used-upn';
   private readonly QUERY_LOGIN_HINT = 'loginhint';
   private readonly QUERY_AUTH_SID = 'auth-sid';
   private isAuthenticatedSubject = new Subject<boolean>();
   private msalInstance!: msal.PublicClientApplication;
   private accountValue?: msal.AccountInfo;
   private sessionAccessToken: AccessToken;
   private apiAccessToken: AccessToken;
   private scopesAsc!: string[];
   private scopesMicrosoftGraph!: string[];
   private useLogging!: boolean;

   constructor(
      private telemetryService: TelemetryService
   ) {
      this.apiAccessToken = new AccessToken();
      this.sessionAccessToken = new AccessToken();
   }

   public async initializeAsync(clientConfiguration: ClientConfiguration): Promise<void> {
      this.scopesAsc = (clientConfiguration.authentication.scopesAsc || []);
      this.scopesMicrosoftGraph = (clientConfiguration.authentication.scopesMicrosoftGraph || []);
      this.useLogging = clientConfiguration.authentication.enableLogging;

      const msalConfig: msal.Configuration = {
         auth: {
            clientId: clientConfiguration.authentication.clientId,
            authority: `https://login.microsoftonline.com/${clientConfiguration.authentication.tenant}`,
            redirectUri: clientConfiguration.authentication.redirectUri,
            postLogoutRedirectUri: clientConfiguration.authentication.postLogoutRedirectUri,
         },
         cache: {
            cacheLocation: 'localStorage',
         }
      };

      if (this.useLogging) {
         msalConfig.system = {
            loggerOptions: {
               logLevel: msal.LogLevel.Info,
               piiLoggingEnabled: clientConfiguration.authentication.enableLogging || false
            }
         };
      }

      this.msalInstance = new msal.PublicClientApplication(msalConfig);
      await this.msalInstance.initialize();
   }

   // -------------------------------------------------------------- Properties

   public get isApiAuthenticated(): boolean {
      return this.apiAccessToken.isValid;
   }

   public get isSessionAuthenticated(): boolean {
      return this.sessionAccessToken.isValid;
   }

   private setSessionAccessToken(accessToken?: AccessToken) {
      const currentIsSessionAuthenticated = this.isSessionAuthenticated;

      this.sessionAccessToken = accessToken || new AccessToken();

      const newIsSessionAuthenticated = this.isSessionAuthenticated;
      if (currentIsSessionAuthenticated === newIsSessionAuthenticated) { return; }

      this.isAuthenticatedSubject.next(newIsSessionAuthenticated);
   }

   public get isAuthenticatedChanged(): Observable<boolean> {
      return this.isAuthenticatedSubject;
   }

   private get account(): msal.AccountInfo | undefined {
      return this.accountValue;
   }

   private set account(value: msal.AccountInfo | undefined) {
      if (this.accountValue === value) { return; }

      this.accountValue = value;
      if (this.accountValue) {
         localStorage.setItem(this.KEY_LAST_USED_UPN, this.accountValue.username);
      }
      else {
         localStorage.removeItem(this.KEY_LAST_USED_UPN);
      }
   }

   // ---------------------------------------------------------- Login / logout

   public async loginAsync(loginHint?: string): Promise<void> {
      const loginRequest: msal.RedirectRequest = {
         scopes: this.scopesMicrosoftGraph,
         loginHint: loginHint,
         extraScopesToConsent: this.scopesAsc
      };

      try {
         await this.msalInstance.loginRedirect(loginRequest);
      } catch (error) {
         const browserAuthError = error as msal.BrowserAuthError;
         if (browserAuthError) {
            switch (browserAuthError.errorCode) {
               case msal.BrowserAuthErrorMessage.interactionInProgress.code:
                  await this.loginAfterRedirectAsync();
                  return;
            }
         }

         if (this.useLogging) { console.error('[Auth]', 'Failed:', error); }
      }
   }

   public async loginAfterRedirectAsync(): Promise<boolean> {
      try {
         const authenticationResult = await this.msalInstance.handleRedirectPromise();
         this.account = authenticationResult?.account || undefined;
         if (authenticationResult?.accessToken && authenticationResult?.expiresOn) {
            const accessToken = new AccessToken(authenticationResult.accessToken, authenticationResult.expiresOn);
            this.setSessionAccessToken(accessToken);
            if (this.useLogging) { console.info('[Auth]', 'Signed-in afer redirect'); }
         }
      }
      catch (error) {
         this.account = undefined;
         this.setSessionAccessToken();

         const message = `Error while acquiring session access token after redirect: ${error}`;
         this.telemetryService.logTrace(message, EAppInsightsSeverityLevel.Warning);
         console.warn('[Auth]', message);
      }
      return this.isSessionAuthenticated;
   }

   public loginFromUrlParameters(): boolean {
      let success = false;
      try {

         const urlSearchParams = new URLSearchParams(window.location.hash.substring(1));
         if (urlSearchParams.has('auth-at')) {
            // Parse JWT
            const base64Url = urlSearchParams.get('auth-at')!.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
               return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            const jwt = JSON.parse(jsonPayload);

            this.account = {
               homeAccountId: '',
               environment: '',
               tenantId: '',
               username: jwt.unique_name,
               name: jwt.name,
               localAccountId: ''
            };

            const accessToken = new AccessToken(urlSearchParams.get('auth-at')!, new Date(jwt.exp * 1000));
            this.apiAccessToken = accessToken;
            this.setSessionAccessToken(accessToken);
            if (this.useLogging) { console.info('[Auth]', 'Signed-in from url parameters'); }
            success = true;
         }
      }
      catch (error) {
         const message = `Error while acquiring session access token from url parameters: ${error}`;
         this.telemetryService.logTrace(message, EAppInsightsSeverityLevel.Warning);
         console.warn('[Auth]', message);
      }
      return success;
   }

   public async loginSSOAsync(promptOnInterationRequired: boolean): Promise<boolean> {
      const url = new URLSearchParams((window.location.search || '').toLowerCase());
      const loginHint = url.get(this.QUERY_LOGIN_HINT) || localStorage.getItem(this.KEY_LAST_USED_UPN) || undefined;
      const sid = url.get(this.QUERY_AUTH_SID) || undefined;

      try {
         const loginRequest: msal.SsoSilentRequest = {
            scopes: this.scopesMicrosoftGraph,
            extraScopesToConsent: this.scopesAsc
         };
         if (loginHint) { loginRequest.loginHint = loginHint }
         if (sid) { loginRequest.sid = sid }

         const authenticationResult = await this.msalInstance.ssoSilent(loginRequest);
         this.account = authenticationResult?.account || undefined;
         if (authenticationResult?.accessToken && authenticationResult?.expiresOn) {
            const accessToken = new AccessToken(authenticationResult.accessToken, authenticationResult.expiresOn);
            this.setSessionAccessToken(accessToken);
            if (this.useLogging) { console.info('[Auth]', 'Signed-in by silent SSO'); }
         }
      } catch (error) {
         this.account = undefined;
         this.setSessionAccessToken();

         if (promptOnInterationRequired && loginHint) {
            await this.loginAsync(loginHint);
         }
      }
      return this.isSessionAuthenticated;
   }

   public async logoutAsync() {
      this.apiAccessToken = new AccessToken();
      this.setSessionAccessToken();
      this.account = undefined;

      await this.msalInstance.logoutRedirect();
   }

   private async acquireTokenSilentlyAsync(scopes: string[]): Promise<AccessToken | undefined> {
      let result: AccessToken | undefined;
      try {
         if (!this.account) {
            const username = localStorage.getItem(this.KEY_LAST_USED_UPN) || '';
            this.account = this.msalInstance.getAccountByUsername(username) || undefined;
         }

         if (this.account) {
            const request: msal.SilentRequest = {
               scopes: scopes,
               account: this.account
            };
            const authenticationResult = await this.msalInstance.acquireTokenSilent(request);
            if (authenticationResult?.accessToken && authenticationResult?.expiresOn) {
               result = new AccessToken(authenticationResult.accessToken, authenticationResult.expiresOn);
            }
         }
      }
      catch (error) {
         if (error instanceof msal.InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            if (this.useLogging) { console.info('[Auth]', 'Acquiring token using redirect'); }
            await this.msalInstance.acquireTokenRedirect({ scopes: scopes });
         } else {
            const message = `[Auth] Error while acquiring API access token silently: ${error}`;
            this.telemetryService.logTrace(message, EAppInsightsSeverityLevel.Warning);
            console.warn(message);
         }
      }
      return result;
   }

   // --------------------------------------------------- User profile handling

   public getUser(): User | undefined {
      if (this.account) {
         return new User(
            this.account.homeAccountId,
            this.account.username,
            this.account.name
         );
      }
      else {
         return undefined;
      }
   }

   public async getApiAccessTokenAsync(): Promise<AccessToken> {
      if (this.apiAccessToken?.expiresOn && this.apiAccessToken.expiresOn >= new Date()) {
         return this.apiAccessToken;
      }
      else {
         this.apiAccessToken = (await this.acquireTokenSilentlyAsync(this.scopesAsc)) || new AccessToken();
         if (this.useLogging) { console.info('[Auth]', 'API access token received:', this.apiAccessToken); }

         return this.apiAccessToken;
      }
   }
}

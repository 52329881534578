import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EAppInsightsSeverityLevel } from '../model/application-insights';


@Injectable()
export class TelemetryService {

   public config: { instrumentationKey?: string; enableAutoRouteTracking?: boolean } = { enableAutoRouteTracking: false };
   private appInsights!: ApplicationInsights;

   constructor() { }

   public init() {
      this.appInsights = new ApplicationInsights({
         config: {
            instrumentationKey: this.config.instrumentationKey,
            enableAutoRouteTracking: this.config.enableAutoRouteTracking // option to log all route changes
         }
      });
      this.appInsights.loadAppInsights();
   }

   public logPageView(name?: string, url?: string) { // option to call manually
      this.appInsights.trackPageView({
         name: name,
         uri: url
      });
   }

   public logEvent(name: string, properties?: { [key: string]: any }) {
      this.appInsights.trackEvent({ name: name }, properties);
   }

   public logMetric(name: string, average: number, properties?: { [key: string]: any }) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
   }

   public logException(exception: Error, message?: string, severityLevel?: EAppInsightsSeverityLevel, properties?: { [key: string]: any }) {

      if (!severityLevel) { severityLevel = EAppInsightsSeverityLevel.Error; }

      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel as number }, properties);

      if (message) {
         this.appInsights.trackTrace({ message: message, severityLevel: severityLevel as number }, properties);
      }
   }

   public logTrace(message: string, severityLevel?: EAppInsightsSeverityLevel, properties?: { [key: string]: any }) {
      this.appInsights.trackTrace({ message: message, severityLevel: severityLevel as number }, properties);
   }

   public setAuthenticatedUserContext(userId: string, accountId?: string, storeInCookie?: boolean) {
      this.appInsights.setAuthenticatedUserContext(userId, accountId, storeInCookie);
   }

   public clearAuthenticatedUserContext() {
      this.appInsights.clearAuthenticatedUserContext();
   }
}

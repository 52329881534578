import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';

import { AscHtmlEditorComponent } from './html-editor.component';

@NgModule({
   declarations: [
      AscHtmlEditorComponent
   ],
   imports: [
      QuillModule.forRoot()
   ],
   exports: [
      AscHtmlEditorComponent
   ]
})
export class AscHtmlEditorModule  {}

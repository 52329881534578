import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AscAutoFocusDirective } from './components/auto-focus/auto-focus.component';
import { AscHtmlEditorModule } from './components/html-editor/html-editor.module';

@NgModule({
   declarations: [
      AscAutoFocusDirective
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      AscHtmlEditorModule
   ],
   exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,

      AscAutoFocusDirective,
      AscHtmlEditorModule
   ]
})
export class SharedModule {
   constructor() { }
}

import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { TelemetryService } from '../services/telemetry.service';
import { ApiEndpoint } from '../model/api-endpoint';


export class FunctionClient {

   public static httpClient: HttpClient;
   public readonly url: string;
   public isBusyChangedSubject = new Subject<boolean>();

   private isBusyValue = false;

   // ------------------------------------------------------------- Constructor

   constructor(
      protected telemetryService: TelemetryService,
      private functionEndpoint: ApiEndpoint
   ) {
      this.url = functionEndpoint.url;
      if (functionEndpoint.code) {
         this.url += `?code=${functionEndpoint.code}`;
      }
   }

   // ----------------------------------------------------- Function operations

   public async callFunctionGetAsync<T>(url: string, options?: {
      headers?: HttpHeaders | {
         [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
         [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
   }): Promise<T> {

      try {
         const httpOptions = options || {};
         (<any>httpOptions).withCredentials = this.functionEndpoint.secured;

         this.setIsBusy(true);

         const data = await firstValueFrom(
            FunctionClient.httpClient.get<T>(url, httpOptions)
         )

         this.setIsBusy(false);

         return data;
      }
      catch (error) {
         this.setIsBusy(false);
         throw error;
      }
   }

   public async callFunctionGetTextAsync(url: string): Promise<string> {
      try {
         this.setIsBusy(true);

         const data = await firstValueFrom(
            FunctionClient.httpClient.get(url, { responseType: 'text', withCredentials: this.functionEndpoint.secured })
         )

         this.setIsBusy(false);
         return data;
      }
      catch (error) {
         this.setIsBusy(false);
         throw error;
      }
   }

   public async callFunctionDeleteAsync<T>(url: string, options?: {
      headers?: HttpHeaders | {
         [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
         [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
   }): Promise<T> {

      try {
         const httpOptions = options || {};
         (<any>httpOptions).withCredentials = this.functionEndpoint.secured;

         this.setIsBusy(true);

         const data = await firstValueFrom(
            FunctionClient.httpClient.delete<T>(url, httpOptions)
         )

         this.setIsBusy(false);
         return data;
      }
      catch (error) {
         this.setIsBusy(false);
         throw error;
      }
   }

   public async callFunctionPostAsync<T>(url: string, body: any | null, options?: {
      headers?: HttpHeaders | {
         [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
         [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
   }): Promise<T> {

      try {
         const httpOptions = options || {};
         (<any>httpOptions).withCredentials = this.functionEndpoint.secured;

         this.setIsBusy(true);

         const data = await firstValueFrom(
            FunctionClient.httpClient.post<T>(url, body, httpOptions)
         )

         this.setIsBusy(false);
         return data;
      }
      catch (error) {
         this.setIsBusy(false);
         throw error;
      }
   }

   public async callFunctionPutAsync<T>(url: string, body: any | null, options?: {
      headers?: HttpHeaders | {
         [header: string]: string | string[];
      };
      observe?: 'body';
      params?: HttpParams | {
         [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
   }): Promise<T> {

      try {
         const httpOptions = options || {};
         (<any>httpOptions).withCredentials = this.functionEndpoint.secured;

         this.setIsBusy(true);

         const data = await firstValueFrom(
            FunctionClient.httpClient.put<T>(url, body, httpOptions)
         )

         this.setIsBusy(false);
         return data;
      }
      catch (error) {
         this.setIsBusy(false);
         return (<HttpErrorResponse>error)?.error;
      }
   }

   // ----------------------------------------------------------- Busy handling

   public get isBusyChanged(): Observable<boolean> {
      return this.isBusyChangedSubject;
   }

   public get isBusy(): boolean {
      return this.isBusyValue;
   }

   private setIsBusy(value: boolean) {
      if (this.isBusyValue === value) { return; }
      this.isBusyValue = value;
      this.isBusyChangedSubject.next(this.isBusyValue);
   }
}

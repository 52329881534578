import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DesktopService } from '../../services/desktop.service';
import { ModuleManagerService } from '../../services/module-manager.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
   selector: 'asc-settings',
   templateUrl: './settings.component.html',
   styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
   public title = 'Einstellungen';

   constructor(
      private activatedRoute: ActivatedRoute,
      private desktopService: DesktopService,
      private moduleManagerService: ModuleManagerService,
      private navigationService: NavigationService
   ) {
      this.activatedRoute.url.subscribe(() => {
         if (this.moduleManagerService.settingsNavigationItems.length > 0 && this.desktopService.canShowSettingsPanel) {
            this.moduleManagerService.settingsNavigationItems[0].entryPoints[0].navigate();
         }
      });
   }

   // -------------------------------------------------------------- Properties

   public get isSettingsMenuVisible(): boolean {
      return this.desktopService.canShowSettingsPanel || (this.activatedRoute.children.length === 0);
   }

   public get isSettingsPanelVisible(): boolean {
      return this.desktopService.canShowSettingsPanel || (this.activatedRoute.children.length > 0);
   }

   // -------------------------------------------------------------- Operations

   public navigateBack() {
      this.navigationService.navigateToSettings();
   }
}

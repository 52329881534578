import { ModuleSettings } from './module-settings';

export class ClientModuleConfiguration {
   public id!: string;
   public settings!: ModuleSettings;

   public static parse(data: any): ClientModuleConfiguration {
      let item = new ClientModuleConfiguration();
      if (data) {
         item = Object.assign(item, data);
      }
      return item;
   }

}

import { ElementRef, Directive, AfterViewInit } from '@angular/core';

@Directive({
   selector: '[ascAutoFocus]',
})
export class AscAutoFocusDirective implements AfterViewInit {

   constructor(private host: ElementRef<HTMLInputElement>) { }

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.host.nativeElement.focus();
      }, 50);
   }
}

import { EProviderType } from '../authentication/model/eprovider-type';
import { ApiEndpoint } from './api-endpoint';

export class ClientConfiguration {

   public production: boolean = false;

   public environment?: string;
   public title?: string;
   public version?: string;

   public apiEndpoints!: {
      Core_ClientConfiguration: ApiEndpoint;
      Core_ModuleSettings: ApiEndpoint;
      Core_ModuleConfiguration: ApiEndpoint;
      Core_User: ApiEndpoint;
      Core_UserProfile: ApiEndpoint;

      Notifications_ApproveMessage: ApiEndpoint;
      Notifications_CreateMessage: ApiEndpoint;
      Notifications_Message: ApiEndpoint;
      Notifications_MessageTemplate: ApiEndpoint;
      Notifications_MessageTemplates: ApiEndpoint;
      Notifications_ModuleSettings: ApiEndpoint;
      Notifications_PreviewMessage: ApiEndpoint;
      Notifications_ReadMessage: ApiEndpoint;
      Notifications_ReadMessages: ApiEndpoint;
      Notifications_RecipientCollectionEntries: ApiEndpoint;
      Notifications_RecipientCollections: ApiEndpoint;
      Notifications_RemoveMessage: ApiEndpoint;

      PasswordManagement_PasswordValidationRules: ApiEndpoint;
      PasswordManagement_Start: ApiEndpoint;

      SuSPasswordReset_PasswordValidationRules: ApiEndpoint;
      SuSPasswordReset_Start: ApiEndpoint;
      SuSPasswordReset_Students: ApiEndpoint;

      Software_WindowsClientSetup: ApiEndpoint;

      UserProfiles_UserProfile: ApiEndpoint;
   };

   authentication!: {
      tenant: string;
      clientId: string;
      provider: EProviderType;
      redirectUri: string;
      postLogoutRedirectUri: string;
      enableLogging: boolean;
      scopesAsc: string[];
      scopesMicrosoftGraph: string[];
      endpoints: string[];
   };

   telemetry!: {
      applicationInsightsInstrumentationKey: string;
   };


   /**
    * Gets the value indicating whether the specified url is protected by AAD
    * and can only be accessed with an access token.
    * @param url 
    * @returns 
    */
   public apiEndpointRequiresAuthentication(url: string): boolean {
      const apiEndpointUrl: string = url.split('?')[0];

      for (const property in this.apiEndpoints) {
         const apiEndpoint: ApiEndpoint = (<any>this.apiEndpoints)[property] as ApiEndpoint;
         if (apiEndpoint && apiEndpoint.url === apiEndpointUrl && apiEndpoint.secured) { return true; }
      }

      return false;
   }
}

import { DurableFunctionCustomStatusError } from "./durable-function-custom-status-error";

export class DurableFunctionError extends Error {
    constructor(
        public status: number,
        public statusText: string,
        message?: string,
        public error?: DurableFunctionCustomStatusError
    ) {
        super(message);
        this.name = 'DurableFunctionError';
    }
}
/* eslint-disable @typescript-eslint/naming-convention */
import { IAscModuleDefinition } from '../i-asc-module-definition';
import { InjectionToken } from '@angular/core';
import { PasswordManagementClientModuleConfiguration } from './model/password-management-client-module-configuration';


export const MODULE_CONFIGURATION = new InjectionToken<PasswordManagementClientModuleConfiguration>('module configuration');

export const PASSWORD_MANAGEMENT_MODE = {
   CHANGE_PASSWORD: 'change-password',
   RESET_PASSWORD: 'reset-password',
   UNLOCK_ACCOUNT: 'unlock-account'
};

export const PASSWORD_MANAGEMENT_ROUTE_PATH = {
   SELECT_MODE: 'select-mode',
   PROMPT_NAME: 'prompt-name',
   ENTER_CODE: 'enter-code',
   DONE: 'done',
   NOTIFICATION: 'notification'
};

export const PASSWORD_MANAGEMENT_DEFINITION: IAscModuleDefinition = {
   moduleId: 'PASSWORD_MANAGEMENT',
   displayName: 'Kennwort verwalten',
   moduleRoute: {
      path: 'password-management',
      loadChildren: () => import('src/modules/password-management/password-management.module').then(m => m.PasswordManagementModule)
   }
};

export const PASSWORD_MANAGEMENT_FUNCTION_EVENT_NAME = {
   USER_FORM_RESPONSE: 'UserFormResponse'
};

export const PASSWORD_MANAGEMENT_ACTIVITY_NAME = {
   INITIALIZE: 'Initialize',
   VERIFY_USER_PERMISSIONS: 'VerifyUserPermissions',
   TRIGGER_2FA: 'Trigger2FA',
   VERIFY_2FA: 'Verify2FA',
   TRIGGER_PASSWORD_MANAGEMENT: 'TriggerPasswordManagement',
   WAIT_FOR_USER_INPUT: 'WaitForUserInput',
   COMPLETED: 'Completed'
};

export const PASSWORD_MANAGEMENT_ERROR_NAME = {
   ERR_UNKNOWN_USER: 'ERR_UNKNOWN_USER',
   ERR_PASSWORD_RESET_DEACTIVATED: 'ERR_PASSWORD_RESET_DEACTIVATED',
   ERR_USER_NOT_ALLOWED: 'ERR_USER_NOT_ALLOWED',
   ERR_TOO_MANY_PASSWORD_RESET_REQUESTS: 'ERR_TOO_MANY_PASSWORD_RESET_REQUESTS',
   ERR_SENDING_SMS_FAILED: 'ERR_SENDING_SMS_FAILED',
   ERR_UNABLE_TO_SET_PASSWORD: 'ERR_UNABLE_TO_SET_PASSWORD',
   ERR_PASSWORD_CONFIRMATION_FAILED_WITH_RETRY: "ERR_PASSWORD_CONFIRMATION_FAILED_WITH_RETRY",
   ERR_PASSWORD_CONFIRMATION_FAILED: "ERR_PASSWORD_CONFIRMATION_FAILED",
   ERR_VERIFICATION_2FA_FAILED_WITH_RETRY: 'ERR_VERIFICATION_2FA_FAILED_WITH_RETRY',
   ERR_VERIFICATION_2FA_FAILED: 'ERR_VERIFICATION_2FA_FAILED',
   ERR_TIME_OUT: 'ERR_TIME_OUT',
   ERR_NO_PASSWORD_PROVIDED: 'ERR_NO_PASSWORD_PROVIDED',
   ERR_MOBILE_NUMBER_NOT_FOUND: 'ERR_MOBILE_NUMBER_NOT_FOUND',
   /**
    * The entered password does not match with the confirmed password.
    */
   ERR_PASSWORD_MISMATCH: 'ERR_PASSWORD_MISMATCH',
   ERR_PASSWORD_ALREADY_USED: 'ERR_PASSWORD_ALREADY_USED',
   ERR_INTERNAL_ERROR: 'ERR_INTERNAL_ERROR',
   ERR_CURRENT_PASSWORD_IS_NOT_CORRECT: 'ERR_CURRENT_PASSWORD_IS_NOT_CORRECT',
   ERR_USER_IS_LOCKED_OUT: 'ERR_USER_IS_LOCKED_OUT',
   ERR_PASSWORD_MGMT_MODE_DEACTIVATED: 'ERR_PASSWORD_MGMT_MODE_DEACTIVATED'
};

export class DurableFunctionCustomStatusError {
   /**
    * Gets the error name (a server- and client-side predefined code).
    */
   public name?: string;
   /**
    * Gets the technical error message.
    */
   public message?: string;
   /**
    * Gets the UTC date-time when the error occurred.
    */
   public timestamp?: string;
}

import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { DurableFunctionInfo } from './durable-function-info';
import { DurableFunctionStatus } from './durable-function-status';
import { DurableFunctionCustomStatus } from './durable-function-custom-status';
import { DurableFunctionCustomStatusError } from './durable-function-custom-status-error';
import { FunctionClient } from './function-client';
import { EAppInsightsSeverityLevel } from '../model/application-insights';
import { TelemetryService } from '../services/telemetry.service';
import { ApiEndpoint } from '../model/api-endpoint';
import { DurableFunctionError } from './durable-function-error';

export class DurableFunctionClient<TOutput, TDurableFunctionCustomStatus extends DurableFunctionCustomStatus<DurableFunctionCustomStatusError>> extends FunctionClient {

   protected info?: DurableFunctionInfo;
   private statusTimer: any;
   private statusChangedSubject = new Subject<DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus>>();
   private activityChangedSubject = new Subject<string>();
   private errorChangedSubject = new Subject<DurableFunctionCustomStatusError>();
   private outputChangedSubject = new Subject<string | TOutput>();
   private _isRunning = false;


   // ------------------------------------------------------------- Constructor

   constructor(
      telemetryService: TelemetryService,
      functionEndpoint: ApiEndpoint,
      private functionName: string
   ) {
      super(telemetryService, functionEndpoint);
   }


   // -------------------------------------------------------------- Properties

   public get instanceId(): string | undefined {
      return (this.info) ? this.info.id : undefined;
   }

   public get isRunning(): boolean {
      return this._isRunning;
   }


   // ---------------------------------------------------------- Start function

   public get canStart(): boolean {
      return !this.info;
   }

   /**
    * Starts a new instance of durable function (method: GET).
    * @throws DurableFunctionError
    */
   public startGetAsync(): Promise<DurableFunctionInfo | undefined> {
      return this.startAsync('GET');
   }

   /**
    * Starts a new instance of durable function (method: POST).
    * @throws DurableFunctionError
    */
   public startPostAsync(parameters?: any): Promise<DurableFunctionInfo | undefined> {
      return this.startAsync('POST', parameters);
   }

   /**
    * @throws DurableFunctionError
    */
   private async startAsync(method: string, parameters?: any): Promise<DurableFunctionInfo | undefined> {
      if (!this.canStart) { return Promise.resolve(undefined); }

      try {
         this._isRunning = true;

         switch (method) {
            case 'GET':
               this.info = await super.callFunctionGetAsync<DurableFunctionInfo>(this.url);
               break;

            case 'POST':
               this.info = await super.callFunctionPostAsync<DurableFunctionInfo>(this.url, parameters);
               break;

            default:
               throw new Error(`No implementation found starting a durable function by http method '${method}'.`);
         }

         this.telemetryService.logTrace(
            `[ASC-Client] Durable function '${this.functionName}' started.`,
            EAppInsightsSeverityLevel.Information,
            {
               prop__Source: this.functionName,
               prop__InstanceId: this.instanceId
            });

         this._isRunning = false;
         return this.info;
      }
      catch (error) {
         let durableFunctionError: DurableFunctionError;
         if (error instanceof HttpErrorResponse) {
            durableFunctionError = new DurableFunctionError(
               error.status,
               error.statusText,
               error.error || error.message
            );
         }
         else if (error instanceof Error) {
            durableFunctionError = new DurableFunctionError(
               0,
               '',
               error.message
            );
         }
         else {
            console.error('Unhandled error type:', error);
            this.telemetryService.logTrace(`Unhandled error type in DurableFunctionClient.startAsync(): ${JSON.stringify(error)}`, EAppInsightsSeverityLevel.Error);
            throw error;
         }


         this._isRunning = false;

         this.telemetryService.logException(
            error as Error,
            `[ASC-Client] Error while starting durable function '${this.functionName}'.`,
            EAppInsightsSeverityLevel.Error,
            {
               prop__Source: this.functionName,
               prop__InstanceId: (this.info || { id: undefined }).id,
               prop__Error: error
            });
         console.error('[DurableFunction]', `Error while starting durable function ${this.functionName}.`, error);

         throw durableFunctionError;
      }
   }

   /**
    * Tries re-connecting to a running orchestration.
    */
   public async reconnectAsync(statusQueryGetUri: string): Promise<DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus> | undefined> {
      let status: DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus> | undefined = undefined;

      try {
         if (!this.isRunning && statusQueryGetUri) {
            status = await this.queryStatusAsync(statusQueryGetUri);
            if (status?.isRunning || false) {
               await this.startPostAsync({ orchestrationId: status.instanceId });

               this.telemetryService.logTrace(
                  `[ASC-Client] Re-connected to a running durable function '${this.functionName}'.`,
                  EAppInsightsSeverityLevel.Information,
                  {
                     prop__Source: this.functionName,
                     prop__InstanceId: status.instanceId
                  }
               );
            }
         }
      }
      catch (error) {
         console.error(error);
      }

      return status;
   }

   // ----------------------------------------------------------- Stop function

   public get canStop(): boolean {
      return !!this.info && this.isRunning;
   }

   /**
    * Terminates the orchestration instance.
    */
   public async stopAsync(reason?: string): Promise<void> {
      try {
         if (!this.info) { return Promise.resolve(); }

         const uri = this.getTerminateUri(reason);
         if (uri) {

            await super.callFunctionPostAsync(uri, null);
            this._isRunning = false;

            this.telemetryService.logTrace(
               `[ASC-Client] Durable function ${this.functionName} stopped.`,
               EAppInsightsSeverityLevel.Information,
               {
                  prop__Source: this.functionName,
                  prop__InstanceId: this.instanceId,
               });
         }
      }
      catch (error) {
         this._isRunning = false;

         const err = error as HttpErrorResponse;
         if (err?.status === 404 || err?.status === 410) {
            // Not found or already terminated
            this.close();
         }
         else {
            this.telemetryService.logException(
               err,
               `[ASC-Client] Error while stopping durable function '${this.functionName}'`,
               EAppInsightsSeverityLevel.Error,
               {
                  prop__Source: this.functionName,
                  prop__InstanceId: this.instanceId,
                  prop__Error: error
               });
            console.error('[DurableFunction]', `Error while stopping durable function ${this.functionName}.`, error);
         }

         throw error;
      }
   }

   // ------------------------------------------------------------- Raise event

   public async raiseEventAsync(eventName: string, value: any): Promise<void> {
      if (!this.info) { return; }

      const uri = this.getSendEventUri(eventName);
      if (uri) {
         await super.callFunctionPostAsync(uri, value, {
            headers: new HttpHeaders()
               .append('Content-Type', 'application/json')
         });
      }
   }

   // ----------------------------------------------------- Get function status

   public get statusChanged(): Observable<DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus> | undefined> {
      return this.statusChangedSubject;
   }

   public get canGetStatus(): boolean {
      return !!this.info && this.isRunning;
   }

   public async getStatusAsync(): Promise<DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus> | undefined> {
      try {
         if (!this.info) { return Promise.resolve(undefined); }

         const status = await this.queryStatusAsync(this.info.statusQueryGetUri);
         if (!status) { return Promise.resolve(undefined); }

         this.statusChangedSubject.next(status);

         if (status.customStatus) {
            if (status.customStatus.activityName) { this.activityChangedSubject.next(status.customStatus.activityName); }
            if (status.customStatus.error) { this.errorChangedSubject.next(status.customStatus.error); }
         }

         switch (status.runtimeStatus) {
            case DurableFunctionStatus.RUNTIME_STATUS_COMPLETED:
               this.telemetryService.logTrace(
                  `[ASC-Client] Durable function '${this.functionName}' completed.`,
                  EAppInsightsSeverityLevel.Information,
                  {
                     prop__Source: this.functionName,
                     prop__InstanceId: this.instanceId,
                     prop__Output: JSON.stringify(status.output)
                  });
               console.log('[DurableFunction]', 'Completed.', status.output);
               this.stopStatusPolling();
               this.outputChangedSubject.next(status.output as TOutput);
               this.close();
               break;

            case DurableFunctionStatus.RUNTIME_STATUS_FAILED:
               this.telemetryService.logTrace(
                  `[ASC-Client] Durable function '${this.functionName}' failed.`,
                  EAppInsightsSeverityLevel.Error,
                  {
                     prop__Source: this.functionName,
                     prop__InstanceId: this.instanceId,
                     prop__Output: JSON.stringify(status.output)
                  });
               console.error('[DurableFunction]', 'Failed.', status.output);
               this.stopStatusPolling();
               this.outputChangedSubject.next(status.output as string);
               this.close();
               break;

            case DurableFunctionStatus.RUNTIME_STATUS_PENDING:
            case DurableFunctionStatus.RUNTIME_STATUS_RUNNING:
               // console.log('[DurableFunction]', 'Still running. History:', data.historyEvents);
               break;
         }

         return status;
      }
      catch (error) {
         let durableFunctionError: DurableFunctionError;
         if (error instanceof HttpErrorResponse) {
            durableFunctionError = new DurableFunctionError(
               error.status,
               error.statusText,
               error.error || error.message
            );
         }
         else if (error instanceof Error) {
            durableFunctionError = new DurableFunctionError(
               0,
               '',
               error.message
            );
         }
         else {
            console.error('Unhandled error type:', error);
            this.telemetryService.logTrace(`Unhandled error type in DurableFunctionClient.getStatusAsync(): ${JSON.stringify(error)}`, EAppInsightsSeverityLevel.Error);
            throw error;
         }

         if (durableFunctionError?.status === 410) {
            // Already terminated
            this.close();
         }
         else {
            this.stopStatusPolling();
            this.errorChangedSubject.next(durableFunctionError);
         }

         throw durableFunctionError;
      }
   }

   /**
    * @throws DurableFunctionError
    */
   public async queryStatusAsync(statusQueryGetUri: string | undefined): Promise<DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus> | undefined> {
      try {
         if (!statusQueryGetUri) { return Promise.resolve(undefined); }

         const data = await super.callFunctionGetAsync<DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus>>(statusQueryGetUri)

         if (data) {
            return Object.assign(new DurableFunctionStatus<TOutput, TDurableFunctionCustomStatus>(), data);
         }
         else {
            return undefined;
         }
      }
      catch (error) {
         const httpError = error as HttpErrorResponse;
         throw new DurableFunctionError(
            httpError.status,
            httpError.statusText,
            httpError.error || httpError.message
         );
      }
   }

   public startStatusPolling(intervall: number = 2000) {
      this.statusTimer = setInterval(async () => {
         await this.getStatusAsync();
      }, intervall);
   }

   public stopStatusPolling() {
      if (!this.statusTimer) { return; }

      clearInterval(this.statusTimer);
      this.statusTimer = undefined;
   }

   // -------------------------------------------------- Custom status handling

   public get activityChanged(): Observable<string> {
      return this.activityChangedSubject
         .pipe(
            distinctUntilChanged<string>(),
            map(data => {
               this.telemetryService.logTrace(
                  `[ASC-Client] Durable function '${this.functionName}' moved to activity '${data}'.`,
                  EAppInsightsSeverityLevel.Information,
                  {
                     prop__Source: this.functionName,
                     prop__InstanceId: this.instanceId,
                     prop__ActivityName: data
                  });

               return data;
            })
         );
   }

   public get errorChanged(): Observable<DurableFunctionError> {
      return this.errorChangedSubject
         .pipe(
            distinctUntilChanged<DurableFunctionCustomStatusError>((x, y) => (x && y && JSON.stringify(x) === JSON.stringify(y))),
            map(data => {
               if (data) {
                  this.telemetryService.logTrace(
                     `[ASC-Client] Durable function ${this.functionName} reported error '${data.name}'.`,
                     EAppInsightsSeverityLevel.Error,
                     {
                        prop__Source: this.functionName,
                        prop__InstanceId: this.instanceId,
                        prop__Error: JSON.stringify(data)
                     });
               }

               return new DurableFunctionError(0, '', undefined, data);
            })
         );
   }

   public get outputChanged(): Observable<string | TOutput> {
      return this.outputChangedSubject;
   }

   // -------------------------------------------------------- Close controller

   private close() {
      this.outputChangedSubject.complete();
      this.info = undefined;
      this._isRunning = false;
   }

   // ---------------------------------------------------------- Helper methods

   private getSendEventUri(eventName: string) {
      return (this.info) ? (this.info.sendEventPostUri || '').replace('{eventName}', eventName) : undefined;
   }

   private getTerminateUri(reason?: string) {
      return (this.info) ? (this.info.terminatePostUri || '').replace('{text}', reason || '') : undefined;
   }
}

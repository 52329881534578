import { UserSettings } from './user-settings';

export class UserProfile {

   public role!: string;
   public userSettings!: UserSettings;

   // Database properties (on-premise)
   public mail?: string;
   public alternativeMail?: string;
   

   public static parse(data: any): UserProfile {
      const userProfile = new UserProfile();
      if (data) {
         userProfile.role = data.role;
         userProfile.userSettings = UserSettings.parse(data.userSettings);
      }
      return userProfile;
   }
}
